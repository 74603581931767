import React, { useState } from 'react';
import styled from '@emotion/styled';

import Modal, { ModalActions, ModalBody, ModalFooter } from 'components/modal';
import Button from 'components/button';
import InputController from 'components/form/input-controller';
import ErrorBar from 'components/error-bar';
import { OrderDetailsResponse, UpdateOrderClientDetails } from 'routes/order-details/redux/types';
import { useAsyncModalState } from 'utils/use-async-modal-state';

interface IEditClientInformationModal {
	onSubmit: (clientDetails: UpdateOrderClientDetails) => void;
	onClose: () => void;
	order?: OrderDetailsResponse;
	isInProgress: boolean;
	error: string | null;
}

const EditClientInformationModal: React.FC<IEditClientInformationModal> = ({
	onSubmit,
	onClose,
	isInProgress,
	error,
	order
}) => {
	const [form, setForm] = useState<UpdateOrderClientDetails>({
		firstName: order?.clientInfo.firstName || '',
		lastName: order?.clientInfo.lastName || '',
		apartment: order?.clientInfo.apartment || '',
		city: order?.clientInfo.city || '',
		country: order?.clientInfo.country || '',
		state: order?.clientInfo.state || '',
		zipcode: order?.clientInfo.zipcode || '',
		streetAddress: order?.clientInfo.streetAddress || '',
		streetAddress2: order?.clientInfo.streetAddress2 || '',
		email: order?.clientInfo.email || '',
		phone: order?.clientInfo.phone || ''
	});

	const { modalState, setModalState } = useAsyncModalState({
		isInProgress,
		error,
		onClose
	});

	const submitForm = () => {
		setModalState('pending');
		onSubmit(form);
	};

	const onChange = (property: string, value: string) => {
		setForm((currentFormState: UpdateOrderClientDetails) => ({
			...currentFormState,
			[property]: value
		}));
	};

	return (
		<Modal title="Update client info" onClose={onClose}>
			{error && <ErrorBar>{error}</ErrorBar>}

			<form
				onSubmit={(event) => {
					event.preventDefault();
					submitForm();
				}}
			>
				<ModalBody>
					<PairGrid>
						<Fieldset>
							<Label htmlFor="firstName">First Name</Label>
							<InputController
								name="firstName"
								value={form.firstName}
								onChange={(event) => onChange(event.target.name, event.target.value)}
								disabled
							/>
						</Fieldset>
						<Fieldset>
							<Label htmlFor="lastName">Last Name</Label>
							<InputController
								name="lastName"
								value={form.lastName}
								onChange={(event) => onChange(event.target.name, event.target.value)}
								disabled
							/>
						</Fieldset>
					</PairGrid>
					<Fieldset>
						<Label htmlFor="streetAddress">Street Address</Label>
						<InputController
							name="streetAddress"
							value={form.streetAddress}
							onChange={(event) => onChange(event.target.name, event.target.value)}
						/>
					</Fieldset>
					<Fieldset>
						<Label htmlFor="streetAddress2">Street Address 2</Label>
						<InputController
							name="streetAddress2"
							value={form.streetAddress2}
							onChange={(event) => onChange(event.target.name, event.target.value)}
						/>
					</Fieldset>
					<PairGrid>
						<Fieldset>
							<Label htmlFor="apartment">Apartment</Label>
							<InputController
								name="apartment"
								value={form.apartment}
								onChange={(event) => onChange(event.target.name, event.target.value)}
							/>
						</Fieldset>
						<Fieldset>
							<Label htmlFor="city">City</Label>
							<InputController
								name="city"
								value={form.city}
								onChange={(event) => onChange(event.target.name, event.target.value)}
							/>
						</Fieldset>
					</PairGrid>
					<TripleGrid>
						<Fieldset>
							<Label htmlFor="country">Country</Label>
							<InputController
								name="country"
								value={form.country}
								onChange={(event) => onChange(event.target.name, event.target.value)}
							/>
						</Fieldset>
						<Fieldset>
							<Label htmlFor="state">State</Label>
							<InputController
								name="state"
								value={form.state}
								onChange={(event) => onChange(event.target.name, event.target.value)}
							/>
						</Fieldset>
						<Fieldset>
							<Label htmlFor="zipcode">Zip Code</Label>
							<InputController
								name="zipcode"
								value={form.zipcode}
								onChange={(event) => onChange(event.target.name, event.target.value)}
							/>
						</Fieldset>
					</TripleGrid>
					<Fieldset>
						<Label htmlFor="email">Email</Label>
						<InputController
							name="email"
							value={form.email}
							onChange={(event) => onChange(event.target.name, event.target.value)}
							disabled
						/>
					</Fieldset>
					<Fieldset>
						<Label htmlFor="phone">Phone Number</Label>
						<InputController
							name="phone"
							value={form.phone}
							onChange={(event) => onChange(event.target.name, event.target.value)}
						/>
					</Fieldset>
				</ModalBody>

				<ModalFooter>
					<ModalActions>
						<Button color="primary" disabled={modalState === 'pending'}>
							Update customer
						</Button>
					</ModalActions>
				</ModalFooter>
			</form>
		</Modal>
	);
};

const Fieldset = styled.fieldset`
	border: 0;
	margin-bottom: 1rem;
`;

const Label = styled.label`
	display: block;
	text-transform: uppercase;
	font-size: 0.9rem;
	margin-bottom: 0.5rem;
`;

const PairGrid = styled.div`
	display: grid;
	column-gap: 12px;
	grid-template-columns: 1fr 1fr;
`;

const TripleGrid = styled.div`
	max-width: 400px;
	display: grid;
	column-gap: 12px;
	grid-template-columns: 6fr 1fr 4fr;
`;

export default EditClientInformationModal;
